import blog_4_1 from '../../assets/img/blog/blog_4_1.jpg';
import blog_4_2 from '../../assets/img/blog/blog_4_2.jpg';
import blog_4_3 from '../../assets/img/blog/blog_4_3.jpg';
import blog_4_4 from '../../assets/img/blog/blog_4_4.jpg';
import blog_4_cover from '../../assets/img/blog/blog_4_cover.jpg';

import tech_info_icon from '../../assets/img/blog/tech-info-icon.svg'
export const Inventory = (props: any) => {
  return (
    <>
    <div className="hero overlay inner-page py-5" style={{
    backgroundImage: `url(${blog_4_cover})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    }}>
      <div className="container">
        <div className="row align-items-center justify-content-center text-center pt-5">
          <div className="col-lg-6">
            <h1 className="heading text-white mb-3 text-with-shadow" data-aos="fade-up"><strong>{props.title}</strong></h1>
          </div>
        </div>
      </div>
    </div>
    <div className="section search-result-wrap">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1 className="post-description-heading">{props.headline}</h1>
                    <p className="post-date">By {props.author} on {props.date}</p>
                    <hr/>
                </div>
            </div>
        </div>
    
    <div className="container">
      <div className="post-content-body">
      <p>Whilst it is very much a work in progress, we wanted to share where we are now, some of the things we think makes our inventory system unique and what we still have to do. In this blog you will see many different UI styles that we have been exploring. </p>
      <p>For those of you unfamiliar with gaming terms, your inventory is basically where you keep all your belongings as you adventure.</p>
<hr/>
    <div className="row">
        <div className="col-lg-6 col-sm-12">
            <img src={blog_4_1} className="img-fluid rounded" />
        </div>
        <div className="col-lg-6 col-sm-12">
            <h2 className="post-heading">Multiple Inventories</h2>
            <p>In Dragginz, you will have multiple inventories available to you. At the bare minimum you will have the items that your character owns, and items that your pet(s) own.</p>
            <p>If you are close to one or more objects in the world that may also hold items (such as a treasure chest or table), this inventory will also become available to you. In this preview it is shown as the Prop tab.</p>
            <p>You can click and drag items within the same tab or move them onto another tab to switch to a new inventory. If there is no space available in the new tab, the item(s) will not be moved.</p>
            <p>It is still TBD if you will be able to transfer items between different characters you own.  This does seem like a useful feature if you have multiple characters, but we need to make sure that it is not game breaking in any way.</p>
        </div>
    </div>
    
<hr/>
    <div className="row">
        <div className="col-lg-6 col-sm-12">
            <h2 className="post-heading">Storage &amp; Stacking</h2>
            <p>We do use a grid system to display icons of every item you are currently holding.  We are experimenting with how many items can be on screen and still be readable.  In our prototype a Player starts out with a 4x4 backpack. This is an earlier screenshot that doesnt have the backpack’s owner shown.</p>
            <p>Most items take up only one space, but there will be some element of Tetris if you happen to craft or pick up something huge.  This would take up a larger number of spaces and may be an irregular shape.  These odd-shaped larger items are the exception though, it is not something you would have to deal with all the time.</p>
            <p>If you have more than one of some types of item, they may stack on top of each other, using less space.  How many can be in a stack depends on the item type.  You can change how many items are stacked together by splitting them into smaller piles.</p>        
            
        </div>
        <div className="col-lg-6 col-sm-12 text-center">
          <img src={blog_4_2} className="img-fluid rounded" />
        </div>
    </div>
    


<hr/>
    <div className="row">
        <div className="col-lg-6 col-sm-12">
            <img src={blog_4_3} className="img-fluid rounded" />
        </div>
        <div className="col-lg-6 col-sm-12">
        <h2 className="post-heading">Overflow Buffer</h2>
            <p>This is a temporary store of inventory spaces only available to the character. Each space can hold an item of any size and shape. Items granted to the player from e.g. a quest will initially be placed into the Item Overflow. The player can then rearrange the character’s inventory grid to make room for these items, or distribute them to other valid characters or pets.</p>
            <p>The player must empty the Overflow before being able to progress with gameplay.  The aim here is to always be able to collect the rewards from quests or killing epic monsters.</p>
            <p>Sometimes when adventuring your character or pet can pick up too many of an item that you just do not want.  If you are not near a shop or the item is worthless, you will want the ability to get rid of it.  We are currently exploring ways to make this easy, without being able to accidentally destroy your most precious items.</p>
       </div>
    </div>

       
<hr/>
    <div className="row">
        <div className="col-lg-6 col-sm-12">
            <h2 className="post-heading">Information Window</h2>
            <p>Lastly, we are doing a lot of experimentation focused on the information we want to be displayed about each item.  For something simple like an Apple, there isnt much that needs to be shown, but when you get to things like weapons or crafted items, things get more complicated.</p>
            <p>There is a lot of information that could be displayed about each item including flavour text, rarity, scarcity (limited editions), weight, shape, number of uses, if it is edible, if you can equip it, trade it etc.</p>
            <p>We are playing around with different ways to show this without making the inventory look completely mad.  Also when to show the information is another area we are exploring.</p>
        </div>
        <div className="col-lg-6 col-sm-12">
            <img src={blog_4_4} className="img-fluid rounded" />
        </div>
    </div>
 
      <p>Anyway that is where we are right now...</p>
      <p>Stay tuned for more sneak peaks coming soon...</p>
      </div>
    </div>
    </div>
    </>
  );
}