import { useEffect, useState } from 'react';
import { mockGetOneCall, mockListCall } from '../utils/api';
import LoadingSpinner from './LoadingSpinner';
import { SignUpForm } from './blog_posts/sign_up_form';
import { NotForProfit } from './blog_posts/not_for_profit';
import { InternetIdentityHacks } from './blog_posts/internet_identity_hacks';
import { Inventory } from './blog_posts/inventory2';
import { Canisters } from './blog_posts/canisters';
import { useParams, useNavigate } from 'react-router-dom';


const BlogView = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate()
  const handleApiCall = async () => {
    setLoading(true);
    setError(null);
    if (!id) {
      navigate("/404");
      return;
    }
    try {
      const result = await mockGetOneCall(id);
      // console.log(result);
      if (!result) {
        navigate("/404");
        return;
      }
      // console.log(result);
      setData(result);
    } catch (err) {
        if (err instanceof Error) {
            setError(err.message);
        }
    } finally {
        setLoading(false);
    }
  };
  useEffect(() => {
    handleApiCall();
  }, []);
  let TemplateComponent = null;  // Default to null
  if (data !== null && Object.keys(data).length > 0) {
    // console.log(data);
    switch(data.id) {
      case 'crypto-1':
        TemplateComponent = SignUpForm;
        break;
      case 'crypto-2':
        TemplateComponent = NotForProfit;
        break;
      case 'crypto-3':
        TemplateComponent = InternetIdentityHacks;
        break;
      case 'crypto-4':
        TemplateComponent = Inventory;
        break;
        case 'crypto-5':
          TemplateComponent = Canisters;
          break;

      default:
        break;
    }
  }
  
  
  // const blob = new Blob([htmlString], { type: 'text/html' });
  return (
    <>
     {loading && <div className="text-center">
        <LoadingSpinner altText="loading" theme="sm" />
      </div>
    }
    {(!loading && data !== null && Object.keys(data).length > 0) && 
      <>
        {TemplateComponent && <TemplateComponent {...data} />}
      </>
    }
   </>
    

  );
}

export default BlogView;