import { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import Isotope from 'isotope-layout';
import 'aos/dist/aos.css';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';

import cavern from '../assets/img/gallery/environments/cavern.jpg';
import shop from '../assets/img/gallery/environments/shop.jpg';
import nhome from '../assets/img/gallery/environments/natural-home.jpg';
import caveinterior1 from '../assets/img/gallery/environments/caveinterior1.jpg';
import caveinterior2 from '../assets/img/gallery/environments/caveinterior2.jpg';
import exploration1 from '../assets/img/gallery/environments/exploration1.jpg';
import exploration2 from '../assets/img/gallery/environments/exploration2.jpg';
import planetsurface from '../assets/img/gallery/environments/a_frozen_icy_shattered_planet.jpg';
import thecore from '../assets/img/gallery/environments/thecore.jpg';
import thecore2 from '../assets/img/gallery/environments/thecore2.jpg';
import concept_for_house from '../assets/img/gallery/environments/concept_for_house.jpg';
import exploration_area from '../assets/img/gallery/environments/exploration_area.jpg';
import exploration_path from '../assets/img/gallery/environments/exploration_path.jpg';
import merfolk from '../assets/img/gallery/environments/merfolk_environment.jpg';
import path_to_adventure from '../assets/img/gallery/environments/path_to_adventure.jpg';


import elf1 from '../assets/img/gallery/characters/elf1.jpg';
import elf2 from '../assets/img/gallery/characters/elf2.jpg';
import elf3 from '../assets/img/gallery/characters/elf3.jpg';
import elf4 from '../assets/img/gallery/characters/elf4.jpg';
import chinchillawarrior from '../assets/img/gallery/characters/chinchillawarrior.jpg';
import birdperson from '../assets/img/gallery/characters/birdperson.jpg';
import possible_plant_creature from '../assets/img/gallery/characters/possible-plant-creature.jpg';
import bramble_monster from '../assets/img/gallery/characters/bramble_monster.jpg';
import cave_monster from '../assets/img/gallery/characters/cave_monster.jpg';
import dusk_elf_female from '../assets/img/gallery/characters/dusk_elf_female_concept.jpg';
import dusk_elf_male from '../assets/img/gallery/characters/dusk_elf_male_concept.jpg';
import initial_dusk_elf from '../assets/img/gallery/characters/initial_dusk_elf_concept.jpg';

import marshdraggin from '../assets/img/gallery/dragginz/marshdraggin.jpg';
import earthdraggin from '../assets/img/gallery/dragginz/earthdraggin.jpg';
import forestdraggin from '../assets/img/gallery/dragginz/forestdraggin.jpg';
import airdraggin from '../assets/img/gallery/dragginz/airdraggin.jpg';
import airdraggin2 from '../assets/img/gallery/dragginz/airdraggin2.jpg';
import draggineggs from '../assets/img/gallery/dragginz/draggineggs.jpg';
import leopardperson from '../assets/img/gallery/dragginz/leopardperson.jpg';
import dragginz1 from '../assets/img/gallery/dragginz/dragginz1.jpg';
import rare_earth_type_draggin_egg from '../assets/img/gallery/dragginz/rare-earth-type-draggin-egg.jpg';
import rare_fire_type_draggin_egg from '../assets/img/gallery/dragginz/rare-fire-type-draggin-egg.jpg';

import draggin_adult_char from '../assets/img/gallery/dragginz/draggin_adult_char.jpg';
import draggin_baby_red from '../assets/img/gallery/dragginz/draggin_baby_red.jpg';
import draggin_blue_adult from '../assets/img/gallery/dragginz/draggin_blue_adult.jpg';
import draggin_blue_char from '../assets/img/gallery/dragginz/draggin_blue_char.jpg';
import draggin_blue_water from '../assets/img/gallery/dragginz/draggin_blue_water.jpg';
import draggin_red_rider from '../assets/img/gallery/dragginz/draggin_red_rider.jpg';
import draggin_white_2 from '../assets/img/gallery/dragginz/draggin_white_2.jpg';


const portfolioItems = [
  {
    filter: 'filter-environments',
    img: caveinterior1,
    title: 'Home Interior',
    desc: 'Initial concepts for interior of a player home.',
  },
  {
    filter: 'filter-environments',
    img: caveinterior2,
    title: 'Home',
    desc: 'Initial concepts for crafting area of a player home.',
  },
  {
    filter: 'filter-environments',
    img: exploration1,
    title: 'Exploration Area',
    desc: 'Concept for exploration area near Keeper Village cavern.',
  },
 {
    filter: 'filter-environments',
    img: exploration2,
    title: 'Exploration Area',
    desc: 'Concept for exploration area near Keeper Village cavern.',
  },
  {
    filter: 'filter-environments',
    img: planetsurface,
    title: 'Surface of Planet Draggin',
    desc: 'A desolate frozen wasteland. The few survivors were forced to start a new life underground.',
  },
 {
    filter: 'filter-environments',
    img: cavern,
    title: 'Cavern',
    desc: 'One of the caverns in the upper layers of the planet.',
  },
  {
    filter: 'filter-environments',
    img: shop,
    title: 'Shop',
    desc: 'A house within the Keeper Village cavern.',
  },
  {
    filter: 'filter-environments',
    img: nhome,
    title: 'Tree Home',
    desc: 'A natural home in the Keeper Village cavern.',
  },
 {
    filter: 'filter-environments',
    img: thecore,
    title: 'Planet Core',
    desc: 'The centre of the planet Draggin is hollow with floating islands orbiting a magical energy source.',
  },
  {
    filter: 'filter-environments',
    img: concept_for_house,
    title: 'Concept for House',
    desc: 'Exploring ideas of what homes could look like. Is everything carved out of rock or can the inhabitants use wood as a building tool?',
  },
  {
    filter: 'filter-environments',
    img: exploration_area,
    title: 'Exploration Area',
    desc: 'An moss-covered natural environment perfect for exploration.',
  },
  {
    filter: 'filter-environments',
    img: exploration_path,
    title: 'Exploration Path',
    desc: 'We like the way that this could easily be underground, but is not dark and gloomy.  Imagine this is the first place you adventure with your Draggin.',
  },
  {
    filter: 'filter-environments',
    img: merfolk,
    title: 'Merfolk Environment',
    desc: 'We intend to have an aquatic race and this could be one area where they are found.',
  },
  {
    filter: 'filter-environments',
    img: path_to_adventure,
    title: 'Path to Adventure',
    desc: 'Further experimentation with how an undeground forest may look.',
  },
 {
    filter: 'filter-environments',
    img: thecore2,
    title: 'Planet Core Outskirts',
    desc: 'The edges of the core are lush, verdant habitats.',
  },
 {
    filter: 'filter-characters',
    img: elf1,
    title: 'Canopy Elf Concepts',
    desc: 'Assorted concepts for Canopy Elves',
  },
 {
    filter: 'filter-characters',
    img: elf4,
    title: 'Canopy Elf Concepts',
    desc: 'Assorted concepts for Canopy Elves',
  },
  {
    filter: 'filter-characters',
    img: elf2,
    title: 'Canopy Elf',
    desc: 'Canopy Elf concept - Female.',
  },
  {
    filter: 'filter-characters',
    img: elf3,
    title: 'Canopy Elf',
    desc: 'Canopy Elf concept - Warrior.',
 },
 {
    filter: 'filter-characters',
    img: chinchillawarrior,
    title: 'Possible Playable Race',
    desc: 'We like the idea of a small, seemingly helpless race that is anything but.',
 },
 {
    filter: 'filter-characters',
    img: birdperson,
    title: 'Possible Playable Race',
    desc: 'A variation of a possible avian species',
 },
 {
    filter: 'filter-characters',
    img: leopardperson,
    title: 'Possible Playable Race',
    desc: 'Another variation of a felinoid species',
  },
  {
    filter: 'filter-characters',
    img: possible_plant_creature,
    title: 'Possible Plant Creature',
    desc: 'One specialism a Canopy Elf can choose is the ability to create and control plant-based creatures.',
  },
  {
    filter: 'filter-characters',
    img: dusk_elf_female,
    title: 'Dusk Elf Female',
    desc: 'An early concept for Dusk Elves.  Needs a lot more neon though.',
  },
  {
    filter: 'filter-characters',
    img: dusk_elf_male,
    title: 'Dusk Elf Male',
    desc: 'An early concept for Dusk Elves. Again needs more colour and not sure what is going on with the second or third set of ears.',
  },
  {
    filter: 'filter-characters',
    img: initial_dusk_elf,
    title: 'Initial Dusk Elf Concept',
    desc: 'Originally this came from a monster concept but it got us thinking of new ways we could portray Dusk Elves.',
  },
  {
    filter: 'filter-characters',
    img: bramble_monster,
    title: 'Bramble Monster',
    desc: 'Concept for an enemy that could be found in overgrown areas.',
  },
  {
    filter: 'filter-characters',
    img: cave_monster,
    title: 'Cave Monster',
    desc: 'A big brute blocks your path.  Maybe you can use your wits to defeat him?',
  },
 {
    filter: 'filter-dragginz',
    img: dragginz1,
    title: 'Dragginz',
    desc: 'Early concepts for various Draggin species',
  },
 {
    filter: 'filter-dragginz',
    img: marshdraggin,
    title: 'Marsh Draggin',
    desc: 'Concept for Draggin species that is aligned with the Earth domain.',
  },
 {
    filter: 'filter-dragginz',
    img: earthdraggin,
    title: 'Earth Draggin',
    desc: 'Another concept for Draggin species that is aligned with the Earth domain.',
  },
 {
    filter: 'filter-dragginz',
    img: forestdraggin,
    title: 'Forest Draggin',
    desc: 'Concept for Draggin species that is aligned with the Earth domain.',
  },
 {
    filter: 'filter-dragginz',
    img: airdraggin,
    title: 'Air Draggin',
    desc: 'Concept for Draggin species that is aligned with the Air domain.',
  },
 {
    filter: 'filter-dragginz',
    img: airdraggin2,
    title: 'Air Draggin',
    desc: 'Another concept for Draggin species that is aligned with the Air domain.',
},
 {
    filter: 'filter-dragginz',
    img: draggineggs,
    title: 'Draggin Eggs',
    desc: 'Initial exploration for Draggin eggs',
  },
  {
    filter: 'filter-dragginz',
    img: rare_earth_type_draggin_egg,
    title: 'Rare Earth Type Draggin Egg',
    desc: 'Exploring various ways we could make special Eggs look unique.',
},
{
  filter: 'filter-dragginz',
  img: rare_fire_type_draggin_egg,
  title: 'Rare Fire Type Draggin Egg',
  desc: 'We want to ensure that the eggs look very much like a Draggin egg rather than a standard birds egg.',
},
{
  filter: 'filter-dragginz',
  img: draggin_adult_char,
  title: 'Player and Fire type Draggin',
  desc: 'Initial concept of a Player and their Fire type Draggin.',
},
{
  filter: 'filter-dragginz',
  img: draggin_baby_red,
  title: 'Fire Type Draggin',
  desc: 'A younger version of a Fire type Draggin.',
},
{
  filter: 'filter-dragginz',
  img: draggin_blue_adult,
  title: 'Adult Air type Draggin',
  desc: 'Exploring using feathers rather than horns and scales.',
},
{
  filter: 'filter-dragginz',
  img: draggin_blue_char,
  title: 'Player and Draggin',
  desc: 'A baby Water type Draggin and their friend.',
},
{
  filter: 'filter-dragginz',
  img: draggin_blue_water,
  title: 'Water type Draggin',
  desc: 'The perfect adventuring pal.',
},
{
  filter: 'filter-dragginz',
  img: draggin_red_rider,
  title: 'Earth type Draggin and rider',
  desc: 'Concept of adventuring with an older Draggin.',
},
{
  filter: 'filter-dragginz',
  img: draggin_white_2,
  title: 'White Draggin',
  desc: 'Another Draggin concept.',
}
];


const Main = () => {
  const portfolioContainerRef = useRef<any>(null);
  const portfolioIsotope = useRef<Isotope | null>(null);
  const [activeFilter, setActiveFilter] = useState('*');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  useEffect(() => {
    AOS.init();
    if (portfolioContainerRef.current) {
      imagesLoaded(portfolioContainerRef.current, function() {
        portfolioIsotope.current = new Isotope(".portfolio-container", {
          itemSelector: '.portfolio-item',
          percentPosition: true,
        });
      }); 
    }
    const portfolioLightbox = GLightbox({
        selector: '.portfolio-lightbox'
    });
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };
  
    window.addEventListener('scroll', headerScrolled);
    window.addEventListener('scroll', handleScroll);
     // Call the function once to check on initial load
     headerScrolled();
     const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
      }
      toggleBacktotop();
      window.addEventListener('scroll', toggleBacktotop);
       // Call the function once to check on initial load

     // Clean up after the effect:
      return () => {
        window.removeEventListener('scroll', headerScrolled);
        window.removeEventListener('scroll', toggleBacktotop);
        window.removeEventListener('scroll', handleScroll);
        portfolioIsotope.current?.destroy();
        portfolioLightbox.destroy();
      };
    

  }, []);

  useEffect(() => {
    if (portfolioIsotope.current) {
      if (activeFilter === "*") portfolioIsotope.current?.arrange({ filter: `*` });
      else portfolioIsotope.current?.arrange({ filter: `${activeFilter}` });
    }
  }, [activeFilter]);

  useEffect(() => {
    const navbarLinks = document.querySelectorAll('#navbar .scrollto');
    const header = document.querySelector('#header');

    if (header && scrollPos > 100) {
      header.classList.add('header-scrolled');
    } else if (header) {
      header.classList.remove('header-scrolled');
    }

    navbarLinks.forEach((navbarLink: any) => {
      if (!navbarLink.hash) return;
      let section = document.querySelector(navbarLink.hash);
      if (!section) return;
      if (scrollPos >= section.offsetTop && scrollPos <= (section.offsetTop + section.offsetHeight)) {
        navbarLink.classList.add('active');
      } else {
        navbarLink.classList.remove('active');
      }
    });

  }, [scrollPos]);

  const handleFilterClick = (filter: any) => {
    // console.log(filter);
    setActiveFilter(filter);
  };


  return (
    <main id="main">
      
      <div id="header" className="hero overlay inner-page bg-primary py-5">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center pt-5">
                        <div className="col-lg-6">
                            <h1 className="heading text-white mb-3 text-with-shadow" data-aos="fade-up">Gallery</h1>
                        </div>
                    </div>
                </div>
            </div>
 
      <section id="gallery" className="portfolio section-bg">
        <div className="container" data-aos="fade-in">
          
            <p>We're using AI for some of our concept art. Even our art director agrees it's a great tool (we told him he had to agree or else.)</p>
            <p>Here are some ideas we've developed so far. This will obviously change, and we'll upload new images every so often. This will give you an idea of what's in our heads.</p>
          
          <ul id="portfolio-flters" className="d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
            <li data-filter="*" className={activeFilter === '*' ? 'filter-active' : ''} onClick={() => handleFilterClick('*')}>All</li>
            <li data-filter=".filter-dragginz" className={activeFilter === '.filter-dragginz' ? 'filter-active' : ''} onClick={() => handleFilterClick('.filter-dragginz')}>Dragginz</li>
            <li data-filter=".filter-environments" className={activeFilter === '.filter-environments' ? 'filter-active' : ''} onClick={() => handleFilterClick('.filter-environments')}>Environments</li>
            <li data-filter=".filter-characters" className={activeFilter === '.filter-characters' ? 'filter-active' : ''} onClick={() => handleFilterClick('.filter-characters')}>Characters</li>            
          </ul>
          <div ref={portfolioContainerRef} className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">  
            {portfolioItems.slice().reverse().map((item, index) => (
                <div key={index} className={`col col-lg-4 col-md-6 portfolio-item ${item.filter}`}>
                  <a href={item.img} data-gallery="portfolioGallery" className="portfolio-lightbox  preview-link portfolio-info-x" title={item.desc}><i className="bi bi-plus-circle text-with-shadow"></i></a>
                    <div className="portfolio-img"><img src={item.img} className="img-fluid" alt={item.title} />            
                    </div>
                    <div className="portfolio-info">
                        <h4>{item.title } </h4>
                        <p>{item.desc}</p>
                        
                    </div>
                </div>
            ))}
            
          </div>
        </div>
      </section>
     
    </main>
  );
}; 

export default Main;