import blog_2_1 from '../../assets/img/blog/blog_2_1.jpg';
import blog_2_2 from '../../assets/img/blog/blog_2_2.png';
import blog_2_3 from '../../assets/img/blog/blog_2_3.jpg';
import blog_2_4 from '../../assets/img/blog/blog_2_4.jpg';
import blog_2_5 from '../../assets/img/blog/blog_2_5.png';
export const NotForProfit = (props: any) => {
  return (
    <>
    <div className="hero overlay inner-page py-5" style={{
      backgroundImage: `url(${blog_2_5})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      }}>
      <div className="container">
        <div className="row align-items-center justify-content-center text-center pt-5">
          <div className="col-lg-6">
            <h1 className="heading text-white mb-3 text-with-shadow" data-aos="fade-up"><strong>{props.title}</strong></h1>
          </div>
        </div>
      </div>
    </div>
    <div className="section search-result-wrap">
      <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h3>{props.headline}</h3>
                    <p className="post-date">{props.date}</p>
                    <hr/>
                </div>
            </div>
        </div>

    <div className="container">
      <div className="post-content-body">
      <p>A lot of people ask why we would even set out to make a game if the aim is not to make a profit from it.  The bottom line is that we want to focus on just making the most enjoyable game to play.  Focusing on the world and the mechanics, rather than metrics.</p>
      <p>The founders of Dragginz have been making games and worlds for over three decades.  Over the years we have experienced how the push for profitability comes at a huge creative cost.</p>
      <hr/>


      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <img src={blog_2_1} className="img-fluid rounded" />
        </div>
        <div className="col-lg-6 col-sm-12">
          <h2 className="post-heading">Neopets</h2>
          <p>Neopets was meant to be a virtual world where you journeyed with your pets.  The webpage and flash games were just things we put together while we made the world.  At the time, Adam and I both had an abundance of ideas, but lacked the funds to support the game.  We got investment which enable us to grow the hardware but were then limited in what we could do by the investors.  The focus shifted towards maximizing sponsorships and revenue.  All possibility of a virtual world ended at that point. </p>
        </div>
      </div>
<hr/>
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <h2 className="post-heading">Meteor Games</h2>
          <p>Our next company Meteor Games was meant to create the virtual world we never got to make, but due to the financial crisis we ended up having to make games for Facebook in order to keep the studio alive. We did make the company profitable, but it came at a cost.</p>
          <p>Our biggest selling item was virtual fertilizer (it sped up the growth of your plants on Island Paradise).  That just didn’t sit right with Adam and I.  Literally selling virtual excrement…  We did make a few more Facebook games, but our heart really wasn’t in it.  It felt wrong to us to be basically manipulating people to spend money again and again and again.  From 2010 we wanted to move on and make a virtual world.  It has taken until now for the stars to align.</p>
        </div>
        <div className="col-lg-6 col-sm-12">
          <img src={blog_2_4} className="img-fluid rounded" />
        </div>
      </div>
<hr/>
      <div className="row">
      <div className="col-lg-6 col-sm-12 text-center">
        <img src={blog_2_2} className="img-fluid rounded" />
        </div>
        <div className="col-lg-6 col-sm-12">
        <p>Dragginz is very much the long game passion project for both founders.  It doesn’t matter how much it costs or how long it takes, we will make Dragginz a game we can be proud of.</p>
        <p>I know we will have to keep repeating this again and again, but Dragginz is 100% free to play.  You do not need to buy anything in order to play ever.  Any purchasable items will be completely optional and purely give cosmetic or social benefits.</p>
        <p>There is no catch, we just want to create a fantastic game.</p>
        <p>The end goal is for the game to make enough money from TBD cosmetic/social upgrades to pay for these costs and team wages.  It doesn’t matter how long it takes, we have faith we can get there.  What we aren’t going to do is rush something out to try to earn as much money as possible.</p>
          
        </div>

      </div>

 
      </div>
    </div>
    </div>
    </>
  );
}