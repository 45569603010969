import blog_3_1 from '../../assets/img/blog/blog_3_1.png';
import blog_3_2 from '../../assets/img/blog/blog_3_2.jpg';
import blog_3_3 from '../../assets/img/blog/blog_3_3.png';
import blog_3_4 from '../../assets/img/blog/blog_3_4.jpg';
import blog_3_5 from '../../assets/img/blog/blog_3_5.jpg';

import blog_3_cover from '../../assets/img/blog/blog_3_cover.jpg';
import tech_info_icon from '../../assets/img/blog/tech-info-icon.svg'
import YouTubeEmbed from '../YouTubeEmbed';

export const InternetIdentityHacks = (props: any) => {
  return (
    <>
    <div className="hero overlay inner-page py-5" style={{
    backgroundImage: `url(${blog_3_cover})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    }}>
      <div className="container">
        <div className="row align-items-center justify-content-center text-center pt-5">
          <div className="col-lg-6">
            <h1 className="heading text-white mb-3 text-with-shadow" data-aos="fade-up"><strong>{props.title}</strong></h1>
          </div>
        </div>
      </div>
    </div>
    <div className="section search-result-wrap">
      <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h3>{props.headline}</h3>
                    <p className="post-date">{props.date}</p>
                    <hr/>
                </div>
            </div>
        </div>
    <div className="container">
      <div className="post-content-body">
 
        <div className="row">
          <div className="col-lg-6 col-sm-12 text-center">
          <img src={blog_3_1} className="img-fluid rounded" />
          </div>
          <div className="col-lg-6 col-sm-12">
          <p>In order to play Dragginz you will need to create an Internet Identity Anchor, this is a unique number that does not get shared to any applications or games you play.  The app maker simply knows that you really are who you say you are, without any personal details, cookies or browsing history being transferred. Relax, this is not as daunting as it sounds!</p>
            <p>The easiest way to create your own Internet Identity Anchor is to go to the Internet Identity dapp: <a href="https://identity.ic0.app/" target="_blank">Internet Identity</a>. Dapp stands for Decentralised Application, which is basically anything built on the Internet Computer.</p>
          </div>
        </div>

<hr/>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2 className="post-heading">First Time Set Up</h2>
            <p>You will be asked to enter your identity anchor if you already have one, or you can select <i>&quot;Create an Internet Identity Anchor&quot;</i>.</p>
            <p>Internet Identity associates each identity anchor with a unique user number. Applications the user logs in to do not learn this user number. Instead, Internet Identity creates a pseudonymous identifier on a per user, per application basis. The application can see it is the same user that logged in before, but does not know user it is or whether the user also uses other applications or services.</p>
            <p>When you select that you would like to create an Internet identity anchor on the II homepage, you will be directed to a screen where you are prompted to provide the name of the device on which you are generating the anchor.</p>
          </div>
          <div className="col-lg-6 col-sm-12">
          <img src={blog_3_2} className="img-fluid rounded" />
          </div>
        </div>

<hr/>

  
            <p className="post-tech-box-text">These devices are known as Pass Keys. They all use something called a TPM chip which is a small, built-in component found in many devices like computers or phones. Its main job is to make your device more secure by protecting important information, like passwords and encryption keys, from being accessed or changed by unauthorised people.</p>
            <p className="post-tech-box-text">Think of it as a little tamper-proof safe inside your device that keeps your sensitive data safe from hackers and other bad guys. It helps make sure that your device and the things you do on it are trustworthy and secure.</p>
            <p className="post-tech-box-text">Detailed directions for different types of devices are available for <a href="https://support.dfinity.org/hc/en-us/articles/8286962694420-How-do-I-add-a-phone-to-my-Internet-Identity-" target="_blank">iPhone or Android phone</a> with fingerprint recognition, <a href="https://support.dfinity.org/hc/en-us/articles/4403351925140-How-do-I-add-another-computer-to-my-Internet-Identity-" target="_blank">laptops</a>, and a <a href="https://support.dfinity.org/hc/en-us/articles/8286962818964-How-do-I-add-a-physical-security-key-to-my-Internet-Identity-" target="_blank">security device</a> such as a Yubikey.</p>


<hr/>
        <div className="row">
          <div className="col-lg-6 col-sm-12 text-center">
            <img src={blog_3_3} className="img-fluid rounded" />
          </div>
          <div className="col-lg-6 col-sm-12">
            <p>Once you have chosen your device (normally your phone or laptop), your device will prompt you to allow "identity.ic0.app" to use either a dedicated security key, or with an authentication method of the device you are using, if that option is available. For example, if your device has biometrics enabled to unlock it, you might see the option to use those as your authentication method. You can also use the password that unlocks your computer or a pin that unlocks your phone, depending on the device you’re using.</p>
            <p>Once you grant access, you will be redirected to solve a captcha. After solving this, you will be prompted to choose an account to sign in to "identity.ic0.app". If you are registering for the first time you can select to <i>sign in with the Passkey</i> (which was generated and is stored on your device) otherwise you can choose to sign in with an external security key. After that, you will be redirected to a screen which displays your newly created identity anchor!</p>
            <p>Note that on this screen there is a suggestion to record your identity anchor number. This is the number that you will need to enter to authenticate to dapps running on the IC.</p>
          </div>
        </div>
<hr/>
        <div className="warning-box">
          <h2 className="post-heading">Recovery Method</h2>
          <p>This is <b>THE MOST IMPORTANT BIT!!!</b>  If you lose the device you use to log in with, you could lose your account.  This is just like adding your email address in standard internet sign ups.  It just means if you lose your password or username, you can ask for a reminder.</p>
          <p>The easiest way is to save a <b>Seed Phrase.</b></p>
          <p>Selecting this option generates a cryptographically-secure seed phrase that you can use to recover an identity anchor. Make sure you store this phrase somewhere safe and it is known only to you, as anyone who knows the seed phrase will be able to take full control of your identity anchor. Note that the first string in your seed phrase is the identity anchor. You will need this number to begin the recovery process.</p>
          <p>As long as you keep your Seed Phrase safe, your account will only be accessible to you.</p>
        </div>

<hr/>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2 className="post-heading">What About The Keystroke Hack?</h2>
            <p>The advantage of using an Internet Identity is that it makes you immune from hacking, phishing and even the recently reported Keystroke Hack.</p>
            <p>Logging in using an Internet Identity creates no sound and requires no keystrokes.  Complete immunity at the touch of a fingerprint, or scan of your face!</p>
          </div>
          <div className="col-lg-6 col-sm-12">
          <img src={blog_3_4} className="img-fluid rounded" />
          </div>
        </div>
<hr/>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <YouTubeEmbed embedId="oxEr8UzGeBo"></YouTubeEmbed>
          </div>
          <div className="col-lg-6 col-sm-12">
          <h2 className="post-heading">Still Confused?</h2>
          <p>There is a great YouTube video that explains Internet Identity here</p>
          <p>There is also a great <a href="https://wiki.internetcomputer.org/wiki/How_to_create_an_Internet_Identity" target="_blank">Wikipedia entry</a> explaining everything shown here and more.</p>
          </div>
        </div>
<hr/>          





        <div className="row">
          <div className="col-lg-6 col-sm-12">
          <h2 className="post-heading">Want To Know More?</h2>
          <p>The Dfinity Team are also working on ways to create an Internet Identity without the need for a Pass Key, so logging in would be even simpler.</p>
          <p>If you want to learn more about the work the Dfinity Team are doing on Internet Identity, there is a great <a href="https://internetcomputer.org/blog/news-and-updates/team-spotlight-ii" target="_blank">Team Spotlight</a> to get you started here.</p>
          
          </div>
          <div className="col-lg-6 col-sm-12">
           <img src={blog_3_5} className="img-fluid rounded" />
          </div>
        </div>




 
      </div>
    </div>
    </div>
    </>
  );
}
