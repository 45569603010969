import blog_5_1 from '../../assets/img/blog/blog_5_1.jpg';
import blog_5_2 from '../../assets/img/blog/blog_5_2.jpg';
import blog_5_3 from '../../assets/img/blog/blog_5_3.jpg';
import blog_5_4 from '../../assets/img/blog/blog_5_4.jpg';
import blog_5_5 from '../../assets/img/blog/blog_5_5.jpg';
import blog_5_cover from '../../assets/img/blog/blog_5_cover.jpg';

import tech_info_icon from '../../assets/img/blog/tech-info-icon.svg'
export const Canisters = (props: any) => {
  return (
    <>
    <div className="hero overlay inner-page py-5" style={{
    backgroundImage: `url(${blog_5_cover})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    }}>
      <div className="container">
        <div className="row align-items-center justify-content-center text-center pt-5">
          <div className="col-lg-6">
            <h1 className="heading text-white mb-3 text-with-shadow" data-aos="fade-up"><strong>{props.title}</strong></h1>
          </div>
        </div>
      </div>
    </div>
    <div className="section search-result-wrap">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1 className="post-description-heading">{props.headline}</h1>
                    <p className="post-date">By {props.author} on {props.date}</p>
                    <hr/>
                </div>
            </div>
        </div>
    
    <div className="container">
      <div className="post-content-body">
      <p>Many people seem puzzled how a block chain can help make a virtual world.  The truth is that the Internet Computer is not simply a block chain like Bitcoin or Ethereum.  It is closer to a technological breakthrough like the creation of TCP/IP.  </p>
<hr/>
    <div className="row">
        <div className="col-lg-6 col-sm-12">
            <img src={blog_5_1} className="img-fluid rounded" />
        </div>
        <div className="col-lg-6 col-sm-12">
            <h2 className="post-heading">TCP/IP</h2>
            <p>TCP/IP stands for Transmission Control Protocol/Internet Protocol and is a suite of communication protocols used to interconnect network devices on the internet.</p>
            <p>TCP/IP specifies how data is exchanged over the internet by providing end-to-end communications that identify how it should be broken into packets, addressed, transmitted, routed and received at the destination. TCP/IP requires little central management and is designed to make networks reliable with the ability to recover automatically from the failure of any device on the network.</p>
            <p>Just like the Internet Computer, TCP/IP is nonproprietary and, as a result, is not controlled by any single company. Therefore, the IP suite can be modified easily. TCP/IP is highly scalable and, as a routable protocol, can determine the most efficient path through the network. It is widely used in current internet architecture.</p>
        </div>
    </div>
    <p>TCP/IP enabled the internet and everything you are familiar with online to be developed.  No TCP/IP = No Internet, no Online Banking, no TikTok, Twitter/X, Facebook, Fortnite Online etc… you get the picture.</p>
    <p>Similarly, the Internet Computer enables the creation of completely new concepts. We really are at the beginning of a never-seen-before new exciting technology!</p>
    <p>I have already written about the <a href="https://dragginz.io/blog/crypto-1" target="_blank">security advantages</a>, so I will not expand upon that here.  Basically, no passwords, no risk of hacks or data leaks. </p>
<hr/>
    <div className="row">
        <div className="col-lg-6 col-sm-12">
            <p>Another main advantage of Internet Computer is that it is not owned by any single company or person.  When your game runs on the Internet Computer, it is independent of all the large cloud service providers (Google Cloud, Amazon Web Services, Microsoft Azure etc.)</p>
            <p>This means that your game isn't affected by a large corporations need to make profit, the pricing is set from the start.  The game also cannot be taken down, unlike if a company decide they no longer wish to host your product.</p>
        </div>
        <div className="col-lg-6 col-sm-12 text-center">
          <img src={blog_5_2} className="img-fluid rounded" />
        </div>
    </div>
    


<hr/>
<p>Aside from the risk of the game being removed or having to change in order to comply with a large corporation’s wishes, large cloud providers also have frequent security breaches.  In the last month alone, two attacks on cloud services have been reported upon, both were disastrous for the companies targeted!</p>
    <div className="row">
        <div className="col-lg-6 col-sm-12">
            <img src={blog_5_3} className="img-fluid rounded" />
            
        </div>
        <div className="col-lg-6 col-sm-12">
        <img src={blog_5_4} className="img-fluid rounded" />
            
       </div>
    </div>

       
<hr/>
    <div className="row">
        <div className="col-lg-6 col-sm-12">
            <h2 className="post-heading">Decentralised Canisters</h2>
            <p>Today, most <a href="https://hbr.org/2022/05/what-is-web3" target="_blank">Web3</a> is built on AWS, and just keeps its tokens and NFTs on a blockchain. Usually that blockchain also runs on AWS.</p>
            <p>The Internet Computer is the only blockchain that completely eliminates the need for the centralized Web2 stack.  In plain English, using the Internet Computer also means we no longer need to use database servers, web servers, DNS services etc.</p>
            <p>We use Canisters which are protected data containers that work across all platforms and programming languages.  They are the building blocks of the Internet Computer.  That means that code and data created on the Internet Computer are tamperproof and unstoppable.</p>
            <p>Techy explanation - A canister state is the entire state of a <a href="https://internetcomputer.org/docs/current/references/glossary/#canister" target="_blank">canister</a> at a given point in time. A canister’s state is divided into user state and system state. The user state is a <a href="https://internetcomputer.org/docs/current/references/glossary/#WebAssembly" target="_blank">WebAssembly</a>
                    module instance and the system state is the auxiliary state maintained by the <a href="https://internetcomputer.org/docs/current/references/glossary/#internet-computer-ic-ic" target="_blank">Internet Computer</a>
                    on behalf of the canister, such as its compute allocation, balance of <a href="https://internetcomputer.org/docs/current/references/glossary/#cycles" target="_blank">cycles</a>, input and output queues, and other metadata.</p>
          <p>Basically canisters are remarkable and make everything more secure, fast and easy!</p>
        </div>
        <div className="col-lg-6 col-sm-12">
            <img src={blog_5_5} className="img-fluid rounded" />
            <figcaption className="figure-caption">Internet Computer is not like this.</figcaption>
        </div>
    </div>
 
      <p>Further reading is here if you are interested in exploring this area in greater depth.</p>
      <p><a href="https://medium.com/dfinity/the-internet-computer-a-blockchain-that-offers-stateful-decentralized-serverless-computing-cdbbfdca4b7e" target="_blank">The Internet Computer: A blockchain that offers stateful decentralized serverless computing</a></p>
      <p><a href="https://cointelegraph.com/news/internet-computer-as-key-alternative-to-aws-services" target="_blank">Internet Computer will be a key alternative to AWS-like services</a></p>
      <p><a href="https://internetcomputer.org/docs/current/concepts/canisters-code" target="_blank">Canisters and code | Internet Computer</a></p>
      </div>
    </div>
    </div>
    </>
  );
}
