import blog_1_1 from '../../assets/img/blog/blog_1_1.jpg';
import blog_1_2 from '../../assets/img/blog/blog_1_2.jpg';
import blog_1_3 from '../../assets/img/blog/blog_1_3.jpg';
export const SignUpForm = (props: any) => {
  return (
    <>
    <div className="hero overlay inner-page py-5" style={{
    backgroundImage: `url(${blog_1_3})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    }}>
      <div className="container">
        <div className="row align-items-center justify-content-center text-center pt-5">
          <div className="col-lg-6">
            <h1 className="heading text-white mb-3 text-with-shadow" data-aos="fade-up"><strong>{props.title}</strong></h1>
          </div>
        </div>
      </div>
    </div>
    <div className="section search-result-wrap">
      <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h3>{props.headline}</h3>
                    <p className="post-date">{props.date}</p>
                    <hr/>
                </div>
            </div>
        </div>

    <div className="container">
      <div className="post-content-body">
      <p>Many questions have been raised on our Discord about how Dragginz utilizes crypto. To address these inquiries and highlight the ways in which the Internet Computer enhances our game, I plan to create a series of blog posts. This first entry will focus on how the Internet Computer improves the account creation and login experience for our players.</p>
      
      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <img src={blog_1_1} className="img-fluid rounded" />
        </div>
        <div className="col-lg-6 col-sm-12">
          <p>Traditionally, signing up for an online game involves completing a form that requires various personal details, such as your name, email, date of birth, username, and password. However, sharing this information comes with risks. Many people have encountered unwanted problems after signing up for online services.</p>
          <p>With Dragginz, we have a unique and secure way of handling your login, and we won't ask you to fill out any sign-up forms or provide personal information. We value your privacy and understand that it's essential to keep your data safe.</p>
          <p>Instead of the traditional sign-up form, we utilize the power of crypto to offer a more secure and convenient experience for our players. By leveraging innovative technologies like Internet Identity, players can create accounts without the need to share sensitive personal information.</p>
          <p>With this improved process, players can enjoy Dragginz without the worry of common issues associated with traditional account sign-ups. By using the Internet Computer's cutting-edge features, we aim to make gaming safer, more enjoyable, and hassle-free for everyone.</p>
        </div>
      </div>      
<hr/>      
      <div className="row">
        <div className="col-lg-6 col-sm-12">
        <p>Internet Identity is like a special key that only you possess, and it works with a super-secure digital lock called a blockchain. This blockchain ensures that only you have access to your accounts, making them incredibly secure.</p>
        <p>With Internet Identity, every app you use gets its own special ID for you. This means your activities on different apps are separate and cannot be linked or traced back to you. So, you can enjoy Dragginz without worrying about your information being tracked or shared.</p>
        <p>Your Internet Identity is designed to be tamper-proof, making it even more secure. With this approach, the need for traditional sign-up forms and concerns about data security become a thing of the past. Your privacy is our priority, and we're excited to offer you this innovative and safe way to enjoy our game!</p>
        </div>
        <div className="col-lg-6 col-sm-12">
          <img src={blog_1_2} className="img-fluid rounded" />
        </div>
      </div>               
<hr/>
      <p>Stay tuned for more blog posts in this series to explore other exciting aspects of how we incorporate crypto to enhance your gaming experience.</p>
      </div>
    </div>
    </div>
    </>
  );
}