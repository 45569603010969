
import lg from '../assets/img/dfn-big.gif';
interface LoadingSpinnerProps {
  /**
   * Alt text to be assigned to the loading image alt text attribute for accessibility
   * Should be set to an empty string when the image is decorative and explained by surrounding text where used.
   */
  altText: string;
  theme?: string;
}

const LoadingSpinner = (props: LoadingSpinnerProps): JSX.Element => {
  const className = props.theme === 'lg' ? 'lg' : 'sm';
  return (
    <div className="row">
      <img
          src={lg}
          alt={ props.altText }
          className={`spinner loading-spinner-${className}`} />
     </div>
  );
}

export default LoadingSpinner;
