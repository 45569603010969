import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
const GA_MEASUREMENT_ID = "G-WBHKH6E0R9"; // Our Measurement ID for Google Analytics 4

import imagesLoaded from 'imagesloaded';
import logo_gold_bigz from '../assets/img/logo_dragginz_gold_bigz.png';
import dragginz_logo_small from '../assets/img/dragginz_logo_lower.png';
import img_dra_web_feature from '../assets/img/dra_web_feature.jpg';

import AOS from 'aos';
import Isotope from 'isotope-layout';
import 'aos/dist/aos.css';

import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import BlogLayout from './BlogLayout';
import BlogView from './BlogView';
import BlogList from './BlogList';
import Plan from './Plan';
import FAQ from './FAQ';
import ImageGallery from './Gallery';
import Meadowland from './Meadowland';

import dra_web_icon_discord from '../assets/img/dra_web_icon_discord.png';
import dra_web_icon_x from '../assets/img/dra_web_icon_x.png';
import dra_web_icon_catalyze from '../assets/img/dra_web_icon_catalyze.png';
import dra_web_icon_openchat from '../assets/img/dra_web_icon_openchat.svg';
import dra_web_icon_youtube from '../assets/img/youtube_social_circle_red.png';

const Page404 = lazy(() => import('./Page404'));
const Main = lazy(() => import('./Main'));


ReactGA.initialize(GA_MEASUREMENT_ID); // Initialise Google Analytics for the website
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Dragginz Homepage"});

export const Loader = () => {
  return (
      <div className="loader-bg">
          <div className="loader-track">
              <div className="loader-fill" />
          </div>
      </div>
  );
}; 


function App() {
  const portfolioContainerRef = useRef<any>(null);
  const portfolioIsotope = useRef<Isotope | null>(null);
  const [activeFilter, setActiveFilter] = useState('*');
  const [isScrolled, setIsScrolled] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [navbarMobile, setNavbarMobile] = useState(false);
  const [activeLink, setActiveLink] = useState<string | null>('#hero');
  
  useEffect(() => {
    const headerScrolled = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    const handleScroll = () => {
      setScrollPos(window.scrollY);
    };
  
    window.addEventListener('scroll', headerScrolled);
    window.addEventListener('scroll', handleScroll);
     // Call the function once to check on initial load
     headerScrolled();
     const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
      }
      toggleBacktotop();
      window.addEventListener('scroll', toggleBacktotop);

      if (window.location.hash) {
        const target: any = document.querySelector(window.location.hash);
        if (target) {
          window.scrollTo({
            top: target.offsetTop - ((document.querySelector('#header') as any)?.offsetHeight || 0),
            behavior: 'smooth',
          });
        }
      }
      const preloader = document.getElementById('preloader');
      if (preloader) {
        preloader.remove();
      }

       // Call the function once to check on initial load

     // Clean up after the effect:
      return () => {
        window.removeEventListener('scroll', headerScrolled);
        window.removeEventListener('scroll', toggleBacktotop);
        window.removeEventListener('scroll', handleScroll);
        portfolioIsotope.current?.destroy();
      };
    

  }, []);

  useEffect(() => {
    if (portfolioIsotope.current) {
      if (activeFilter === "*") portfolioIsotope.current?.arrange({ filter: `*` });
      else portfolioIsotope.current?.arrange({ filter: `${activeFilter}` });
    }
  }, [activeFilter]);

  useEffect(() => {
    const navbarLinks = document.querySelectorAll('#navbar .scrollto');
    const header = document.querySelector('#header');

    // if (header && scrollPos > 100) {
    //   header.classList.add('header-scrolled');
    // } else if (header) {
    //   header.classList.remove('header-scrolled');
    // }

    // navbarLinks.forEach((navbarLink: any) => {
    //   if (!navbarLink.hash) return;
    //   let section = document.querySelector(navbarLink.hash);
    //   if (!section) return;
    //   if (scrollPos >= section.offsetTop && scrollPos <= (section.offsetTop + section.offsetHeight)) {
    //     navbarLink.classList.add('active');
    //   } else {
    //     navbarLink.classList.remove('active');
    //   }
    // });
    

  }, [scrollPos]);

  const handleLinkClick = (linkTo: string) => {
    setNavbarMobile(false);
    setActiveLink(linkTo);  // set active link to the value of 'to' prop
  };
  const handleNavToggle = () => {
    setNavbarMobile(!navbarMobile);
  };

  const navigate = useNavigate();  

  const handleScrollTo = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    // Close the mobile navigation menu
    setNavbarMobile(false);

    // Extract hash from the href attribute
    const target = event.currentTarget.getAttribute('href');
    const hash = target?.split('/').pop() || '';  // Extracts '#about' from '/#about'
  
    // Check if it's a valid hash
    if (hash && hash.startsWith('#')) {
      setActiveLink(hash);
      const targetElement = document.querySelector(hash);
      const header = document.querySelector('#header') as HTMLElement;
     
      if (targetElement && header) {
        window.scrollTo({
          top: targetElement.getBoundingClientRect().top + window.scrollY - header.offsetHeight,
          behavior: 'smooth',
        });
      } else {
        // If the targetElement doesn't exist on the current page, navigate to the main page
        navigate('/');
        // After navigation, use a timeout to ensure the new page is rendered before trying to scroll
        setTimeout(() => {
          const newTargetElement = document.querySelector(hash);
          const header = document.querySelector('#header') as HTMLElement;
          if (newTargetElement && header) {
            window.scrollTo({
              top: newTargetElement.getBoundingClientRect().top + window.scrollY - header.offsetHeight,
              behavior: 'smooth',
            });
          }
        }, 50); // Small timeout to allow for the new page to render
      }
    }
};

  return (
    <div className="App">
      <header id="header" className={isScrolled ? " fixed-top header-scrolled" : "fixed-top"} >
      <div className="container d-flex align-items-center">
      <a href="/" className="logo me-auto"><img src={dragginz_logo_small} alt="" className="img-fluid" /></a> 
      <nav id="navbar" className={`navbar ${navbarMobile ? 'navbar-mobile' : ''}`}>
        <ul>

          <li className="text-with-shadow"><a className={`nav-link ${activeLink === '#about' ? 'active' : ''} scrollto`} onClick={handleScrollTo} href="/#about">About</a></li>
          <li className="text-with-shadow"><a className={`nav-link ${activeLink === '#science' ? 'active' : ''} scrollto`} onClick={handleScrollTo} href="/#science">The Science</a></li>
          <li className="text-with-shadow"><a className={`nav-link ${activeLink === '#team' ? 'active' : ''} scrollto`} onClick={handleScrollTo} href="/#team">Team</a></li>
          <li className="text-with-shadow"><Link to='plan' className={activeLink === 'plan' ? 'active' : ''}  onClick={() => handleLinkClick('plan')}>Timeline & FAQ</Link></li>
          <li className="text-with-shadow"><Link to='blog' className={activeLink === 'blog' ? 'active' : ''}  onClick={() => handleLinkClick('blog')}>News</Link></li>
          <li className="text-with-shadow"><Link to='gallery' className={activeLink === 'gallery' ? 'active' : ''} onClick={() => handleLinkClick('gallery')}>Gallery</Link></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle" onClick={handleNavToggle}></i>
      </nav>
      </div>
      </header>
      
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/blog/*' element={<BlogLayout />}>
            <Route index element={<BlogList  />} />
            <Route path=":id" element={<BlogView />} />
            <Route path="*" element={<Page404 />} />
          </Route>
          <Route path='/plan' element={<Plan />} />
          <Route path='/gallery' element={<ImageGallery />} />
          {/* <Route path='/meadowland' element={<Meadowland />} /> */}
          <Route path='/404' element={<Page404 />} />
          <Route path='*' element={<Page404 />} />
        </Routes>
      </Suspense>
      <footer id="footer">
      <div className="container footer-bottom clearfix ">
      <div className="social-media">
        <a href="https://discord.gg/dragginz" target="_blank"><img className="social-media-icon" src={dra_web_icon_discord}></img></a>
        <a href="https://x.com/dragginzgame" target="_blank"><img className="social-media-icon" src={dra_web_icon_x}></img></a>
        <a href="https://catalyze.one/groups/Dragginz" target="_blank"><img className="social-media-icon" src={dra_web_icon_catalyze}></img></a>
        <a href="https://oc.app/community/24t4l-hyaaa-aaaar-ar2rq-cai/?ref=vhrev-7iaaa-aaaaf-ad56q-cai" target="_blank"><img className="social-media-icon" src={dra_web_icon_openchat}></img></a>
        <a href="https://www.youtube.com/@dragginzgame" target="_blank"><img className="social-media-icon" src={dra_web_icon_youtube}></img></a>
      </div>
      <div className="copyright">
        Copyright &copy; 2023 <strong><span>Dragginz</span></strong>. All Rights Reserved
      </div>
        </div>
      </footer>
      <a href="#" className={`back-to-top ${isActive ? "active d-flex align-items-center justify-content-center" : "d-flex align-items-center justify-content-center"}`}><i className="bi bi-arrow-up-circle-fill"></i></a>
    </div>
    
  );
}

export default App;
