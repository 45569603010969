import { useEffect, useState } from 'react';
import { mockGetOneCall } from '../utils/api';
import LoadingSpinner from './LoadingSpinner';
import { SignUpForm } from './blog_posts/sign_up_form';
import { Accordion } from 'react-bootstrap';
import content_faq from '../assets/content/faq.json';
import AOS from 'aos';
import dra_web_proto_img1 from '../assets/img/dra_web_proto_img1.jpg';
import dra_web_proto_img2 from '../assets/img/dra_web_proto_img2.jpg';
import dra_web_proto_img3 from '../assets/img/dra_web_proto_img3.jpg';

const Plan = () => {

  useEffect(() => {
    AOS.init();
     
  }, []);
    return(
        <main id="main">
            <div id="header" className="hero overlay inner-page bg-secondary py-5">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center pt-5">
                        <div className="col-lg-12">
                            <h1 className="heading text-white mb-3 text-with-shadow" data-aos="fade-up">Dragginz Timeline</h1>
                        </div>
                    </div>

                    <div className="plan">
                      <p className="plan-text text-white plan-text-margin">This game has a long history, and we are excited to finally realise our decades-old vision! This timeline provides insight into the history and highlights our plans for the future development of Dragginz.</p>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">1980s</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">Adam visits the Caves of Drach in Majorca. While recovering from severe sunburn, he writes an adventure inspired by the caves.</p></div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">1990s</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">Adam creates Darkheart MUD, drawing from his gaming experiences (Dungeons and Dragons, Vampire: The Masquerade, Fighting Fantasy, and early Amiga Games).</p></div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">1999</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">Adam and Donna develop the concept of a multilayered virtual world to explore with a pet. While awaiting the application's completion, they build the website Neopets.com. The website gains immense popularity, shifting the focus away from the original application concept.</p></div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2007</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">Following the sale of Neopets, the Powells establish a studio to create Twin Skies, revisiting the concept of a vast 3D world. The 2008 financial crisis leads the studio to pivot to social media games, though many concepts from this period persist in Dragginz.</p></div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2012</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">Adam and Donna revisit the idea of a 3D multilayered world and begin building tools in Unity with a team of talented individuals.</p></div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2014</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">The Powells learn about Ethereum and blockchain technology advancements, considering the use of smart contracts in their game, initially codenamed &quot;Moglings.&quot;</p></div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2016</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">The Powells encounter limitations with Ethereum, particularly scalability issues, leading to a pause in development.</p></div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2017</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">Adam and Donna discover the Internet Computer blockchain, a technology solving many of their previous issues. They decide to build Dragginz on the Internet Computer.</p></div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2021</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">The Internet Computer launches, and work on the current iteration of Dragginz begins.</p></div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2022</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12">
                                <p className="plan-text">The team size increases.</p>
                                <p className="plan-text">Backend recode from Motoko to Rust.</p>
                            </div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2023 Q1</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12">
                                <p className="plan-text">Planning begins for an SNS raise.</p>
                                <p className="plan-text">Explore using AI to aid the design process.</p>
                            </div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2023 Q2</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12">
                                <p className="plan-text">The team opts against an SNS raise, finding an alternative solution.</p>
                                <p className="plan-text">Adam acquires 51% of existing SNS1 tokens for Dragginz governance.</p>
                            </div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2023 Q4</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12">
                                <p className="plan-text">&quot;Meadowland&quot; preview launch.</p>
                                <p className="plan-text">Plans to mint extra SNS1 tokens debated and agreed</p>
                                <p className="plan-text">Development of &quot;Blockworld&quot; begins.</p>
                            </div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2024 Q1</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12">
                                <p className="plan-text">February 10, 2024, marks the Year of the Draggin.</p>
                                <p className="plan-text">March 11th, 2024 - All SNS1 tokens multiplied by 800,000</p>
                                <p className="plan-text">March 25th - Dragginz Swiss Foundation registered</p>
                                <p className="plan-text">April 2nd, 2024 - SNS1 renamed to DKP (Dragginz Karma Points)</p>
                            </div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2024 Q2</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12">
                              <p className="plan-text">&quot;Blockworld&quot; preview launch.</p>
                            </div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2024 Q4</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">Alpha launch of the single-player experience in a multiplayer world.</p></div>
                        </div>

                        <div className="row content plan-item">
                            <div className="col-lg-1 col-md-2 col-sm-12"><p className="plan-header">2025</p></div>
                            <div className="col-lg-11 col-md-10 col-sm-12"><p className="plan-text">Multiplayer functionality launch.</p></div>
                        </div>




                    </div>

                </div>
            </div>
            
            <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
          <h1 className="heading text-black mb-3" data-aos="fade-up">Frequently Asked Questions</h1>
            <p>&nbsp;</p>
          </div>
          <div className="faq-list">
            <Accordion>
            {content_faq.sections.map((section, sectionIndex) => (
              <Accordion.Item key={'S' + sectionIndex.toString()} eventKey={'S' + sectionIndex.toString()}>
                <Accordion.Header>
                  <strong>
                    {section.title.toUpperCase()}
                  </strong>
                </Accordion.Header>
                <Accordion.Body>
                  {section.items.map((item, itemIndex) => (
                    <Accordion>
                      <Accordion.Item key={'S' + sectionIndex.toString() + 'Q' + itemIndex.toString()} eventKey = {'S' + sectionIndex.toString() + 'Q' + itemIndex.toString()}>
                        <Accordion.Header>
                          <strong> <i className="bi bi-question-circle"></i>&nbsp;
                            {item.question}
                          </strong>
                        </Accordion.Header>
                        <Accordion.Body>
                          {item.answer.map((answerParagraph, answerParagraphIndex) => (
                            <p key={answerParagraphIndex}>{answerParagraph}</p>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ))} 
            </Accordion>
            
          </div>
        </div>
      </section>
        </main>


    );
}

export default Plan;