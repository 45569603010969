import blog_1_cover from '../assets/img/blog/blog_1_cover.jpg';
import blog_2_cover from '../assets/img/blog/blog_2_cover.jpg';
import blog_3_square from '../assets/img/blog/blog_3_square.jpg';
import blog_4_square from '../assets/img/blog/blog_4_square.jpg';
import blog_5_square from '../assets/img/blog/blog_5_square.jpg';

export const getBase64FromUrl = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}



// api.ts
export const mockListCall = async (): Promise<any[]> => {
  const blog1cover = await getBase64FromUrl(blog_1_cover);
  const blog2cover = await getBase64FromUrl(blog_2_cover);
  const blog3square = await getBase64FromUrl(blog_3_square);
  const blog4square = await getBase64FromUrl(blog_4_square);
  const blog5square = await getBase64FromUrl(blog_5_square);
  
  const blogList = [
    {
      id: 'crypto-5',
      img: blog5square,
      date: 'Aug. 27th, 2023',
      author: 'Donna',
      title: 'Why? - Choosing to develop on the Internet Computer',
      headline: 'The Internet Computer is a completely new concept, perfect for building a virtual world.',
      description: 'Many people seem puzzled how a block chain can help make a virtual world.  The truth is that the Internet Computer is not simply a block chain like Bitcoin or Ethereum.  It is closer to a technological breakthrough like the creation of TCP/IP.'
    },
    {
      id: 'crypto-4',
      img: blog4square,
      date: 'Aug. 25th, 2023',
      author: 'Donna',
      title: 'Sneak Peek - Inventory',
      headline: 'We have been working on Inventory for several months now and feel we have a pretty solid system in place.',
      description: 'We have been working on Inventory for several months now and feel we have a pretty solid system in place. Whilst it is very much a work in progress, we wanted to share where we are now, some of the things we think makes our inventory system unique and what we still have to do.'
    },
    {
      id: 'crypto-3',
      img: blog3square,
      date: 'Aug. 25th, 2023',
      author: 'Donna',
      title: 'Crypto - Internet Identity and Hacks',
      headline: 'Logging in using an Internet Identity creates no sound and requires no keystrokes.',
      description: 'Logging in using an Internet Identity creates no sound and requires no keystrokes. Complete immunity at the touch of a fingerprint, or scan of your face!'
    },
    {
      id: 'crypto-2',
      img: blog2cover,
      date: 'Aug. 23rd, 2023',
      author: 'Donna',
      title: 'Why? - A not-for-profit MMO',
      headline: 'Find out why the Dragginz founders are dedicated to making a truly free gaming experience.',
      description: 'Find out why the Dragginz founders are dedicated to making a truly free gaming experience.'
    },
    {
      id: 'crypto-1',
      img: blog1cover,
      title: 'Crypto - The death of the sign up form',
      date: 'Aug. 23rd, 2023',
      author: 'Donna',
      headline: 'Discover how the Internet Computer simplifies and secures account creation on Dragginz.',
      description: 'Discover how the Internet Computer simplifies and secures account creation on Dragginz.',
    }
  ];  

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      
      resolve(blogList);
      // Uncomment the below line to simulate an error
      // reject("Error fetching data");
    }, 1);
  });
};


export const mockGetOneCall = async (id: string): Promise<any[]> => {
  const blog1cover = await getBase64FromUrl(blog_1_cover);
  const blog2cover = await getBase64FromUrl(blog_2_cover);
  const blog3square = await getBase64FromUrl(blog_3_square);
  const blog4square = await getBase64FromUrl(blog_4_square);
  const blog5square = await getBase64FromUrl(blog_5_square);

  const blogList = [
    {
      id: 'crypto-5',
      img: blog5square,
      date: 'Aug. 28th, 2023',
      author: 'Donna',
      title: 'Why? - Choosing to develop on the Internet Computer',
      headline: 'The Internet Computer is a completely new concept, perfect for building a virtual world.',
      description: 'Many people seem puzzled how a block chain can help make a virtual world.  The truth is that the Internet Computer is not simply a block chain like Bitcoin or Ethereum.  It is closer to a technological breakthrough like the creation of TCP/IP.'
    },
    {
      id: 'crypto-4',
      img: blog4square,
      date: 'Aug. 25th, 2023',
      author: 'Donna',
      title: 'Sneak Peek - Inventory',
      headline: 'We have been working on Inventory for several months now and feel we have a pretty solid system in place.',
      description: 'We have been working on Inventory for several months now and feel we have a pretty solid system in place. Whilst it is very much a work in progress, we wanted to share where we are now, some of the things we think makes our inventory system unique and what we still have to do.'
    },
    {
      id: 'crypto-3',
      img: blog3square,
      date: 'Aug. 25th, 2023',
      author: 'Donna',
      title: 'Crypto - Internet Identity and Hacks',
      headline: 'Logging in using an Internet Identity creates no sound and requires no keystrokes.',
      description: 'Logging in using an Internet Identity creates no sound and requires no keystrokes. Complete immunity at the touch of a fingerprint, or scan of your face!'
    },
    {
      id: 'crypto-2',
      img: blog2cover,
      date: 'Aug. 23rd, 2023',
      author: 'Donna',
      title: 'Why? - A not-for-profit MMO',
      headline: 'Find out why the Dragginz founders are dedicated to making a truly free gaming experience.',
      description: 'Find out why the Dragginz founders are dedicated to making a truly free gaming experience.'
    },
    {
      id: 'crypto-1',
      img: blog1cover,
      title: 'Crypto - The death of the sign up form',
      list_img: '',
      date: 'Aug. 23rd, 2023',
      author: 'Donna',
      headline: 'Discover how the Internet Computer simplifies and secures account creation on Dragginz.',
      description: 'Discover how the Internet Computer simplifies and secures account creation on Dragginz.',
    }
  ];
  return new Promise((resolve, reject) => {
      const entry: any = blogList.find((obj: any) => obj.id === id);
      setTimeout(() => {
          resolve(entry);
          // Uncomment the below line to simulate an error
          // reject("Error fetching data");
      }, 0);
  });
};