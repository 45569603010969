import {  Outlet } from 'react-router-dom';

const BlogLayout = () => {

  return (
  <main id="main">
     <Outlet />
  </main>
   
  );
}

export default BlogLayout;