import { Link } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { mockListCall } from '../utils/api';
import LoadingSpinner from './LoadingSpinner';
import Pagination from '../utils/pagination';
import nuance_logo from '../assets/img/blog/nuance-logo.png';
import blockchaingamer_logo from '../assets/img/blog/blockchaingamer-logo.png';
import gameishard_logo from '../assets/img/blog/gameishard-logo.jpg';
import baizeresearch_logo from '../assets/img/blog/baizeresearch-logo.jpg';

const pageSize = 4;

const BlogList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);

  const handleApiCall = async () => {
    setLoading(true);
    setError(null);
    
    try {
        const result = await mockListCall();
        setData(result);
    } catch (err) {
        if (err instanceof Error) {
            setError(err.message);
        }
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {
    handleApiCall();
  }, []);

  const pageClicked = (page: number) => {
    setPage(page);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [page, data]);

  
  return (
    <>
     <div className="hero overlay inner-page bg-primary py-5">
      <div className="container">
        <div className="row align-items-center justify-content-center text-center pt-5">
          <div className="col-lg-6">
            <h1 className="heading text-white mb-3 text-with-shadow" data-aos="fade-up">News and Blog</h1>
          </div>
        </div>
      </div>
    </div>



    <div className="section search-result-wrap">
      <div className="container">

        <div className="row content press-item">
                     
        <span><a href="https://nuance.xyz/dragginz"><img className="press-image" src={nuance_logo} /></a>
          <p className="press-header">
            Our Dragginz Blog
          </p>
          <p className="press-text">From time to time we create our own articles that we think would be helpful or of interest to the Dragginz Community.  The articles will be a combination of tech explainers, sneak previews, deep dives into certain features and anything else we think may be useful to post about.</p>
          <p className="press-text">These blogs are posted under the Dragginz publication on Nuance (A blogging site built on the Internet Computer).</p>
          <p className="press-text">Click <a href='https://nuance.xyz/dragginz'>here</a>  to find the latest articles -  <a href='https://nuance.xyz/dragginz'>www.nuance.xyz/dragginz</a></p>
        </span>
      </div>

      <div className='row'>
        <div className="col-lg-12">
          <p>Here are some of the latest articles that have been written about Dragginz. If you would like to get in touch with the Dragginz team to discuss possible interviews, or to request a press pack pleae email us <a href="press@dragginz.io">here</a>.</p>
        </div>
      </div>

      <div className="row content press-item">                  
        <span><a href="https://www.blockchaingamer.biz/features/interviews/27766/neopets-creator-donna-powell-dragginz-mmorpg-icp/"><img className="press-image" src={blockchaingamer_logo} /></a>
          <div className="press-date">Oct 17<sup>th</sup> 2023</div>
          <div className="press-header">
            <a href="https://www.blockchaingamer.biz/features/interviews/27766/neopets-creator-donna-powell-dragginz-mmorpg-icp/">Why the original Neopets creators are building Dragginz MMORPG on ICP</a>
          </div>
          <div className="press-credit">- Jenny Jordan, Blockchain Gamer</div>
          <div className="press-spacer"></div>
          <p className="press-text">Third time’s a charm, at least that’s what Neopets creators Adam Powell and Donna 
            Powell are looking to fulfil with their latest title Dragginz. While this 3D MMORPG was 
            the initial idea the couple had for late 1990’s hit Neopets, neither the pet game nor 
            game studio Meteor’s attempt of building it turned out as intended.</p>
        </span>
      </div>

      <div className="row content press-item">                  
        <span><a href="https://mora.app/planet/a4irq-xyaaa-aaaan-qd55a-cai/07D6NTC9KSVVBKTDQBS40KBZC8"><img className="press-image" src={baizeresearch_logo} /></a>
          <div className="press-date">Oct 11<sup>th</sup> 2023</div>
          <div className="press-header">
            <a href="https://mora.app/planet/a4irq-xyaaa-aaaan-qd55a-cai/07D6NTC9KSVVBKTDQBS40KBZC8">The founders of the web game &quot;Neopets&quot;with 100 million players enter Web3 games!</a>
          </div>
          <div className="press-credit">- Baize Research</div>
          <div className="press-spacer"></div>
          <p className="press-text">As the price of SNS-1 tokens skyrockets, fully on-chain game @dragginzgame is 
            trending in the #ICP community. The game was created by Adam and Donna Powell, 
            the founder couple of &quot;Neopets&quot;. It is positioned as "Neopets meets Minecraft" and 
            combines the two elements of &quot;pet development&quot; and &quot;open world&quot; Fusion. The goal is 
            to attract mainstream players around the world who have no crypto knowledge. </p>
          <p><i>(Chinese language article)</i></p>
        </span>
      </div>

        </div>
      </div>
    <div className="spacer">&nbsp;</div>
    </>
   
  );
}

export default BlogList;